// COMMON :: START

#mainContainer {
  margin-top: -15px;
  min-height: 80vh;
}

// are
.are {
  &__block {
    display: flex;
    align-items: center;
    min-height: 67px;
    border: solid 1px #ffffff7f;
    background-color: #009688;
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }

    > div {
      &:nth-of-type(1) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 79px;
      }

      &:nth-of-type(2) {
        width: 100%;
        max-width: calc(100% - 79px);
        padding-right: 5px;
        @include adaptive(767) {
          padding-top: 5px;
          padding-bottom: 5px;
        }

        span {
          font-size: 16px;
          color: #ffffff;
        }
      }
    }

    &-circle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background-color: #ffffff;
      @include adaptive(575) {
        width: 24px;
        height: 24px;
      }

      i {
        position: relative;
        top: -2px;
        width: 22px;
        height: 13px;
        border-style: solid;
        border-width: 0 0 3px 3px;
        border-color: #009688;
        transform: rotate(-45deg);
        @include adaptive(575) {
          width: 14px;
          height: 8px;
          border-width: 0 0 2px 2px;
        }
      }
    }
  }
}
// are :: end

// nav
.nav {
  &--header {
    padding-bottom: 12px;

    .nav__list {
      vertical-align: baseline;

      justify-content: center;
      @include adaptive(991) {
        flex-direction: column;
      }

      &-item {
        @include adaptive(991) {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }

      &-btn {
        color: #333333;

        @include adaptive(991) {
          min-height: 45px;
        }

        span.linkedin {
          position: relative;
          left: 0;
          width: 19px;
          height: 16px;
          display: inline-block;
          background: url(../img/linkedin.png) no-repeat 0 0;
        }
      }

      &-item-active {
        font-weight: 600;
      }
    }
  }

  &--footer {
    padding-left: 107px;
    @include adaptive(991) {
      padding-left: 30px;
    }
    @include adaptive(767) {
      padding-left: 0;
      padding-bottom: 38px;
    }

    .nav__list-btn {
      color: #ffffff;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;

    &-item {
      padding-left: 20px;
      padding-right: 20px;
      @include adaptive(767) {
        &:first-of-type {
          padding-left: 0;
        }
        &:last-of-type {
          padding-right: 0;
        }
      }
      @include adaptive(374) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    &-btn {
      white-space: nowrap;
      display: flex;
      align-items: center;
      line-height: 1.43;
      letter-spacing: 1px;
      text-transform: uppercase;

      font-family: Roboto, sans-serif;
      font-weight: 400;

      &:hover, &:focus, &:visited, &:active {
        text-decoration: none;
      }
    }
  }

  &--header {
    display: block;

    .nav__list {
      vertical-align: baseline;

      justify-content: center;
      @include adaptive(991) {
        flex-direction: column;
      }

      &-item {
        @include adaptive(991) {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }
  }
}
// nav :: end

// nav :: user profile
.header-user-profile {
  .nav {
    &--header {
      .nav__list {
        display: block;
        float: right;
        margin-right: 52px;

        @include adaptive(991) {
          display: flex;
          flex-direction: column;
          margin-right: 0;
          float: none;
        }

        &-item {
          float: left;

          @include adaptive(991) {
            float: none;
            padding-left: 0 !important;
            padding-right: 0 !important;
          }
        }
      }
    }
  }
}

.header-user-profile.header-legacy-internal {
  .nav {
    &--header {
      .nav__list {
        margin-top: -10px;

        @include adaptive(991) {
          margin-top: 0;
        }
      }
    }
  }
}

// logo
.logo {
  display: flex;
  align-items: flex-start;

  &--footer {
    position: relative;
    top: -9px;
    @include adaptive(767) {
      top: 0;
      justify-content: center;
      margin-bottom: 32px;
    }

    svg {
      width: 205px;
      fill: #fff;
    }

    img {
      width: 205px;
    }
  }
  &--header {
    position: relative;

    .is-hideScroll & {
      z-index: 1001;
    }

    svg {
      width: 268px;
      height: 67px;
      filter: drop-shadow(0px 3px 5px rgba(219, 219, 219, 0.65));
      @include adaptive(767) {
        width: 172px;
        height: 42px;
      }
    }

    img {
      width: 268px;
      height: 67px;
      filter: drop-shadow(0px 3px 5px rgba(219, 219, 219, 0.65));
      @include adaptive(767) {
        width: 172px;
        height: 42px;
      }
    }
  }
}
// logo :: end

// .copyright
.copyright {
  display: flex;
  align-items: center;
  @include adaptive(767) {
    justify-content: center;
  }

  p {
    line-height: 1.43;
    letter-spacing: 1px;
    color: #ffffff;
    margin-bottom: 0;
    @include adaptive(767) {
      text-align: center;
    }

    span {
      @include adaptive(767) {
        display: none;
      }
    }

    a {
      text-decoration: underline;
      color: #ffffff;
      @include adaptive(767) {
        display: block;
      }
    }
  }
}
// .copyright :: end

// subscribe
.subscribe {
  &__form {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 362px;
    height: 42px;
    border-radius: 6px;
    background-color: #ffffff;

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 39px;
      height: 100%;

      .icon-subscribe {
        font-size: 13px;
        color: rgba(74, 74, 74, 0.23);
      }
    }

    input,
    button {
      outline: none;
      box-shadow: none;
      border: none;
      border-radius: 0;
      min-height: 100%;
    }

    input {
      width: 100%;
      max-width: calc(100% - 110px);
      padding: 5px 15px 5px 39px;

      font-size: 16px;
      line-height: 1;
      @include inputPlaceholder {
        color: rgba(#333333, 0.7);
        font-size: 14px;
      }
    }

    button {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 110px;
      background-color: #45b8ac;
      font-size: 16px;
      font-family: Roboto, sans-serif;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
    }
  }
  &__messages {
    color: #fff;
    margin-top: 10px;
  }
}
// subscribe :: end

// COMMON :: END
