// FOOTER :: START

footer.footer {
  min-height: 172px;
  background-color: #e91d63;

  &.footer-legacy {
    .c-grid {
      max-width: 1195px;

      @include respond(small) {
        max-width: 1195px;
      }

      @include respond(wide-small) {
        max-width: 1195px;
      }

      @include respond(medium) {
        max-width: 970px;
      }

      @include respond(large) {
        max-width: 970px;
      }

      @include respond(extra-large) {
        max-width: 1195px;
      }

    }
  }

  .footer__wrapper {
    display: flex;
    align-items: center;
    @include adaptive(767) {
      flex-wrap: wrap;
    }

    &-0 {
      padding-top: 44px;
      padding-bottom: 15px;
      @include adaptive(767) {
        padding-top: 45px;
        padding-bottom: 32px;
      }

      .footer__wrapper-left,
      .footer__wrapper-right {
        width: 50%;
        @include adaptive(767) {
          width: 100%;
        }
      }

      .footer__wrapper-left {
        padding-left: 5px;
        @include adaptive(767) {
          padding-left: 0;
        }
      }

      .footer__wrapper-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include adaptive(767) {
          flex-wrap: wrap;
          justify-content: center;
        }

        .nav {
          display: none;
          @include adaptive(767) {
            display: block;
          }
        }
      }
    }
    &-1 {
      padding-top: 15px;
      padding-bottom: 36px;
      @include adaptive(767) {
        padding-top: 0;
        padding-bottom: 47px;
      }

      .footer__wrapper-left,
      .footer__wrapper-right {
        @include adaptive(767) {
          width: 100%;
        }
      }

      .footer__wrapper-right {
        .nav {
          @include adaptive(767) {
            display: none;
          }
        }
      }
    }
  }
}

// FOOTER :: END
