// BUTTON :: START

// ==================================================
// ==================================================
// ==================================================

$bth-width: 296px;
$bth-height: 48px;
$btn-border-width: 1px;
$btn-border-color: rgba(#44b4a8, 1);
$bth-border-radius: 0;
$bth-box-shadow: none;
$bth-background-color: rgba(#44b4a8, 1);
$bth-color: rgba(#fff, 1);
$bth-font-size: 16px;
$bth-font-weight: 500;

$btn-color--border: rgba(#000, 1);

// ==================================================
// ==================================================
// ==================================================

.c-btn {
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: $bth-width;
  height: $bth-height;
  border: $btn-border-width solid $btn-border-color;
  box-shadow: $bth-box-shadow;
  border-radius: $bth-border-radius;
  color: $bth-color;
  font-size: $bth-font-size;
  font-weight: $bth-font-weight;
  line-height: 1.25;
  letter-spacing: 1.1px;

  &--border {
    background-color: transparent;
    color: $btn-color--border;
  }

  &--bg {
    background-color: $bth-background-color;
    @include transition(color, background-color);

    &:hover {
      background-color: transparent;
      color: #44b4a8;
      @include transition(color, background-color);
    }
  }
}

// BUTTON :: END