// HEADER :: START

header.header {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 67px;
  @include adaptive(767) {
    min-height: 52px;
  }

  &.header-legacy-internal {
    .c-grid {
      max-width: 1195px;

      @include respond(small) {
        max-width: 1195px;
      }

      @include respond(wide-small) {
        max-width: 1195px;
      }

      @include respond(medium) {
        max-width: 970px;
      }

      @include respond(large) {
        max-width: 970px;
      }

      @include respond(extra-large) {
        max-width: 1195px;
      }
    }
  }

  &.header-legacy {
    position: relative;

    .c-grid {
      max-width: 1195px;

      @include respond(small) {
        max-width: 1195px;
      }

      @include respond(wide-small) {
        max-width: 1195px;
      }

      @include respond(medium) {
        max-width: 970px;
      }

      @include respond(large) {
        max-width: 970px;
      }

      @include respond(extra-large) {
        max-width: 1195px;
      }
    }
  }

  .header__bg {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 51px;
    background-color: #ffffff;
    box-shadow: 0px 3px 5px 1px rgba(219, 219, 219, 0.65);
    //box-shadow: 0 15px 0 0 rgba(#000, 0.05);
    @include adaptive(767) {
      height: 100%;
      //box-shadow: 0 8px 10px 0 rgba(#000, 0.05);
    }
  }

  .header__wrapper {
    display: flex;
    align-items: center;
    height: 100%;

    &-mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      @include adaptive(991) {
        display: none;
        overflow-y: auto;
        z-index: 1000;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 51px;
        left: 0;
        width: 100%;
        height: calc(100% - 51px);
        padding: 50px 15px;
        background-color: rgba(#fff, 0.95);
        @include transition(transform);

        &.is-open {
          display: flex;
        }
      }
      @include adaptive(767) {
        top: 52px;
        height: calc(100% - 52px);
      }
    }

    &-left,
    &-middle,
    &-right {
      min-height: 100%;
      @include adaptive(991) {
        min-height: auto;
      }
    }

    &-left {
      width: 100%;
      max-width: 272px;
      @include adaptive(991) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 100%;
      }

      .hamburger {
        display: none;
        margin-bottom: 12px;
        @include adaptive(991) {
          display: flex;
        }
        @include adaptive(767) {
          margin-top: 10px;
          margin-bottom: 0;
        }
      }
    }

    &-middle {
      flex: 1;
      min-width: 1px;
      max-width: 100%;
      @include adaptive(991) {
        flex: 0;
      }
    }

    &-right {
      display: flex;
      align-items: center;
      padding-bottom: 15px;
      width: 100%;
      max-width: 167px;
      justify-content: space-between;

      &-reg-close {
        max-width: 225px;
      }

      &-logged-in {
        max-width: 110px;
      }

      @include adaptive(991) {
        flex-direction: column;
        justify-content: center;
        max-width: 100%;
      }
    }
  }

  .header__btn {
    display: flex;

    &-pre {
      margin-right: 24px;
      white-space: nowrap;
      line-height: 1.43;
      letter-spacing: 1px;
      color: #333333;
      text-transform: uppercase;
      font-family: Roboto, sans-serif;
      font-weight: 400;

      &:hover, &:focus, &:visited, &:active {
        text-decoration: none;
        color: #333333;
      }

      @include adaptive(991) {
        min-height: 45px;
        margin-right: 0;
      }
    }

    &-active {
      font-weight: 600;
    }

    &-login {
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 97px;
      height: 28px;
      border: 1px solid #45b8ac;
      background-color: #45b8ac;
      text-transform: uppercase;
      font-weight: 500;
      line-height: 1.43;
      letter-spacing: 1px;
      color: #ffffff;
      font-family: Roboto, sans-serif;

      &:hover, &:focus, &:visited, &:active {
        text-decoration: none;
      }
      @include transition(color, background-color);

      &:hover {
        background-color: transparent;
        color: #44b4a8;
        @include transition(color, background-color);
      }
    }

    &-logout {
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 110px;
      height: 28px;
      border: 1px solid #3f3f3f;
      background-color: #3f3f3f;
      text-transform: uppercase;
      font-weight: 500;
      line-height: 1.43;
      letter-spacing: 1px;
      color: #ffffff;
      font-family: Roboto, sans-serif;

      &:hover, &:focus, &:visited, &:active {
        text-decoration: none;
      }
      @include transition(color, background-color);

      &:hover {
        background-color: transparent;
        color: #3f3f3f;
        @include transition(color, background-color);
      }
    }
  }
}

// HEADER :: END
